import { firestore } from 'firebase/app';

export const HomeReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_DATA':
            return action.db;
        case 'EDIT_HOME':
            firestore().collection('home').doc('text').update({
                text: action.text
            });
            return state;
        default:
            return state;
    }
  } 