import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import Login from '../../components/Login';
import AdminPanel from '../../components/Admin/AdminPanel'
import { Container, Row } from 'react-bootstrap';

const AdminPage = () => {
    const { auth } = useContext(AuthContext);

    return ( 
        <Container className="admin_page">
            <Row>
                {auth.isAuth ? <AdminPanel /> : (
                    <div className="w-100 d-flex justify-content-center">
                        <Login />
                    </div>
                )}
            </Row>
        </Container>
     );
}
 
export default AdminPage;