import React, { createContext, useReducer } from 'react';
import { authReducer } from '../reducers/AuthReducer';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const initialState = {
        isAuth: false,
        user: null
    };
    
    const [ auth, dispatch ] = useReducer(authReducer, initialState)

    return ( 
        <AuthContext.Provider value={{ auth, dispatch}}>
            { props.children }
        </AuthContext.Provider>
     );
}
 
export default AuthContextProvider;