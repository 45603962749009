import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { authFirebase } from '../../helpers/authHelper';


const Login = (props) => {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const { dispatch } = useContext(AuthContext);

    const handleSubmit = async (e) =>{
        e.preventDefault();
        const data = await authFirebase(user, password);
        // dispatch({ type: 'LOGIN', user: { user, password }})
        
        dispatch({ type: 'LOGIN', user: data})

    }
    return ( 
        <div className="login">
            <h2>Admin Panel</h2>
            <form onSubmit={handleSubmit} className="d-flex flex-column">
                <input type="email" name="user" value={user} onChange={(e) => setUser(e.target.value)} placeholder="Username" required/>
                <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required/>
                <div className="button-wrapper d-flex justify-content-center align-items-center">
                    <input className="btn btn--medium" type="submit" value="Log in"/>
                    <div>Lost password?</div>
                </div>
            </form>
        </div>
     );
}
 
export default Login;