import React from 'react';
import { Container } from 'react-bootstrap';

const PageNotFound = () => {
    return (
        <Container className="page-not-found mt-4 text-center">
            <img src="/assets/images/404.png" alt=""/>
        </Container>
    )
}

export default PageNotFound;