import React, { useState, useContext } from 'react';
import { Container, Row, Table } from 'react-bootstrap';
import { SkillsContext } from '../../contexts/SkillsContext';

const Skills = () => {
    const [ skillName, setSkillName ] = useState('');
    const [ skillKnowledge, setSkillKnowledge ] = useState('');
    const [ editMode, setEditMode ] = useState(false);
    const [ showForm, setShowForm ] = useState(false);
    const [ uid, setUid ] = useState('');
    const { skill, skillDispatch } = useContext(SkillsContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        !editMode 
            ? skillDispatch({ type: 'ADD_SKILL', skill: {skillName, skillKnowledge}})
            : skillDispatch({ type: 'UPDATE_SKILL', skill: {id: uid, skillName, skillKnowledge}})
        setEditMode(false);
        setShowForm(false);
        setSkillName('');
        setSkillKnowledge('');
    }

    const handleEdit = (id, title, knowledge) => {
        setUid(id);
        setSkillName(title);
        setSkillKnowledge(knowledge);
        setShowForm(true);
        setEditMode(true);
    }

    const handleRemove = id => {
        skillDispatch({ type: 'REMOVE_SKILL', id });
    }

    return ( 
        <Container className="w-100">

            <Row className="justify-content-center">
                {!showForm ? <button className="btn btn--medium" onClick={() => setShowForm(true)}>Add New Skill</button>
                :
                    <form className="d-flex flex-column" onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor="skillname">Skill Name</label>
                        <input type="text" name="skillname" id="skillname" value={skillName} onChange={e => setSkillName(e.target.value)} required/>

                        <label className="mt-3" htmlFor="skillknowledge">Skill Knowledge</label>
                        <input type="number" name="skillknowledge" id="skillknowledge" max="100" value={skillKnowledge} onChange={e => setSkillKnowledge(e.target.value)} required/>

                        <div className="d-flex mt-3">
                            <input className="btn mr-3" type="submit" value={editMode ? "Save changes" : "Add new skill"}/>
                            <button className="btn" onClick={() => setShowForm(false) }>Cancel</button>
                        </div>
                    </form>
                }
            </Row>

            <Row className="mt-5 mb-5">
                <Table responsive striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Skill</th>
                        <th>Knowledge</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    { skill.length > 0 && skill.map((skill, i) => 
                            (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>{skill.title}</td>
                                    <td>{skill.knowledge + "%"}</td>
                                    <td>
                                        <button className='btn mr-3' onClick={()=> handleRemove(skill.id)}> Remove Skill</button>
                                        <button className='btn' onClick={()=> handleEdit(skill.id, skill.title, skill.knowledge)}> Edit Skill</button>
                                    </td>
                                </tr>
                            )
                        )
                    }
                </tbody>
                </Table>
            </Row>

        </Container>
     );
}
 
export default Skills;