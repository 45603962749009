import { firestore } from 'firebase/app';

export const ProjectsReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_PROJECT':
            
            firestore().collection('projects').add({
                title: action.project.title,
                text: action.project.text,
                imgUrl: action.project.imgUrl,
                github: action.project.github,
                demo: action.project.demo
            });            
            return state;
        case 'REMOVE_PROJECT':
            firestore().collection('projects').doc(action.id).delete();
            return state;
        case 'UPDATE_PROJECT':
            firestore().collection('projects').doc(action.project.id).update({
                title: action.project.title,
                text: action.project.text,
                imgUrl: action.project.imgUrl,
                github: action.project.github,
                demo: action.project.demo
            });
            return state;
        case 'LOAD_DATA':
            return action.db;
        default:
            return state;
    }
  } 