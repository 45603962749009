import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Cards from "../../components/Card";
import { ProjectsContext } from "../../contexts/ProjectsContext";

const ProjectsPage = () => {
  const { projects } = useContext(ProjectsContext);

  return (
    <Container className="projects_page mb-5">
      <Row>
        {projects.length > 0 &&
          projects.map((project, i) => (
            <Col xs="12" md="4" className="mb-4" key={i}>
              <Cards
                type="projects_page--card"
                title={project.title}
                text={project.text}
                imgUrl={project.imgUrl}
                github={project.github}
                demo={project.demo}
              />
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default ProjectsPage;
