import React, { useState } from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  const [status, setStatus] = useState(false);
  const currentDate = new Date();
  return (
    <Container
      fluid={true}
      className={`footer fixed-bottom footer${status ? "--open" : "--closed"}`}
    >
      <footer className="text-center footer_container">
        <div className="footer_container--arrow">
          <div
            onClick={() => {
              setStatus(!status);
            }}
          >
            <img src="/assets/icons/up-arrow.svg" alt="" />
          </div>
        </div>
        <div className="footer_container--text">
          Sebastian Casal © {currentDate.getFullYear()}
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
