import React, { useState, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap';
import AdminNavbar from './AdminNavbar';
import AdminHome from './AdminHome';
import Skills from './Skills';
import Projects from './Projects';

//Providers
import SkillsContextProvider from "../../contexts/SkillsContext";
import HomeContextProvider from "../../contexts/HomeContext";
import ProjectsContextProvider from "../../contexts/ProjectsContext";



const AdminPanel = () => {
    const [selected, setSelected] = useState('Home');
    const [component, setComponent] = useState("");

    const menuItems = [
        {
            item: 'Home',
            component: <HomeContextProvider><AdminHome /></HomeContextProvider>
        },
        {
            item: 'Skills',
            component: <SkillsContextProvider><Skills /></SkillsContextProvider>
        },
        {
            item: 'Projects',
            component: <ProjectsContextProvider><Projects /></ProjectsContextProvider>
        }
    ];

    useEffect(() => {
        const componentSelected = menuItems.filter(component => component.item === selected);
        setComponent(componentSelected[0].component);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);   

    return ( 
        <Container className="admin_panel d-flex align-items-center flex-column">
            <Row>
                <h2>Admin Panel</h2>
            </Row>
            <Row className="mb-5">
                <AdminNavbar 
                    menuItems={menuItems}
                    selected={selected}
                    setSelected={setSelected}
                />
            </Row>
            <Row className="w-100">
                { component }
            </Row>
        </Container>
     );
}
 
export default AdminPanel;