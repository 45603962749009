import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import 'firebase/analytics';


const firebaseConfig = {
    apiKey: "AIzaSyChRWSfdpPaP9zqmyqbRFWzv33dE7BLQLI",
    authDomain: "casalox.firebaseapp.com",
    databaseURL: "https://casalox.firebaseio.com",
    projectId: "casalox",
    storageBucket: "casalox.appspot.com",
    messagingSenderId: "67172860747",
    appId: "1:67172860747:web:0b629970d176548abb1ece",
    measurementId: "G-6S9Y8EGWR6"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
