import firebase from 'firebase/app';
import 'firebase/auth';

const authFirebase = async (email, password) => {
    let response = await firebase.auth().signInWithEmailAndPassword(email, password);
    return {isAuth: true ,user: response.user.email };
    
}

export {
    authFirebase
}