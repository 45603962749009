import React from 'react';
import { Card } from 'react-bootstrap';

const Cards = ({ type, title, text, imgUrl, github, demo, children }) => {

    const handleClick = url => {
        window.open( url, '_blank')
    }

    return (
        <Card className={type}>
            <Card.Img variant="top" src={imgUrl} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    {text}
                </Card.Text>
            </Card.Body>
            <Card.Footer className="d-flex flex-column p-0">
                <div>
                    {children}
                </div>
                <div className="d-flex">
                    {
                        github &&   <button className="footer-item" onClick={ () => {handleClick(github) }}>Github</button>
                    }
                    {
                        demo   &&   <button className="footer-item" onClick={ () => {handleClick(demo) }}>Demo</button>
                    }
                </div>
            </Card.Footer>
        </Card>
    )
}

export default Cards;