import { firestore } from 'firebase/app';

export const SkillsReducer = (state, action) => {
    switch(action.type){
        case 'ADD_SKILL':
            firestore().collection('skills').add({
                title: action.skill.skillName,
                knowledge: action.skill.skillKnowledge
            });            
            return state;
        case 'REMOVE_SKILL':
            firestore().collection('skills').doc(action.id).delete();
            return state;
        case 'UPDATE_SKILL':
            firestore().collection('skills').doc(action.skill.id).update({
                title: action.skill.skillName,
                knowledge: action.skill.skillKnowledge
            });
            return state;
        case 'LOAD_DATA':
            return action.db;
        default:
            return state;
    }
}