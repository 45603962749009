import React, { useContext, useState, useEffect } from 'react';
import { HomeContext } from '../../contexts/HomeContext';

const AdminHome = () => {
    const [ textArea, setTextArea ] = useState('');
    const { homeText, homeDispatcher } = useContext(HomeContext);

    useEffect(() => {
        setTextArea(homeText);
    }, [homeText]);

    const handleSubmit = e => {
        e.preventDefault();        
        homeDispatcher({ type: 'EDIT_HOME', text: textArea});
    }

    return ( 
        <div className="admin-home d-flex justify-content-center w-100 mb-5">
            <form className="d-flex flex-column mb-5" onSubmit={e => handleSubmit(e)}>
                <label htmlFor="aboutme">About Me</label>
                <textarea name="aboutme" id="aboutme" cols="40" rows="15" value={textArea} onChange={(e)=> setTextArea(e.target.value)}></textarea>
                <input className="btn btn--medium" type="submit" value="Save"/>
            </form>
        </div>
     );
}
 
export default AdminHome;