import React, { createContext, useReducer, useEffect } from 'react';
import { HomeReducer } from '../reducers/HomeReducer';
import firebase from 'firebase/app';
import 'firebase/firestore';

export const HomeContext = createContext();

const HomeContextProvider = (props) => {

    const [ homeText, homeDispatcher ] = useReducer(HomeReducer, '');

    useEffect(() => {
        const db = firebase.firestore();

        db.collection("home").onSnapshot((querySnapshot => {
            let data= 'nothing';           
            querySnapshot.forEach(doc => data = doc.data().text);
            homeDispatcher({ type: 'LOAD_DATA', db: data})
        }))
      }, []);

    return ( 
        <HomeContext.Provider value={{ homeText, homeDispatcher }}>
            {props.children}
        </HomeContext.Provider>
     );
}
 
export default HomeContextProvider;