import React, { createContext, useReducer, useEffect } from 'react';
import { ProjectsReducer } from '../reducers/ProjectsReducer';
import firebase from 'firebase/app';
import 'firebase/firestore';

export const ProjectsContext = createContext();

const ProjectsContextProvider = (props) => {
    const [projects, projectDispatcher] = useReducer(ProjectsReducer, []);

    useEffect(() => {
        const db = firebase.firestore();

        db.collection("projects").onSnapshot((querySnapshot => {
            const data = []            
            querySnapshot.forEach(doc => data.push({
                id: doc.id,
                title: doc.data().title,
                text: doc.data().text,
                imgUrl: doc.data().imgUrl,
                github: doc.data().github,
                demo: doc.data().demo,
            }));
            projectDispatcher({ type: 'LOAD_DATA', db: data})
        }))
      }, []);

    return ( 
        <ProjectsContext.Provider value={{ projects, projectDispatcher }}>
            {props.children}
        </ProjectsContext.Provider>
     );
}
 
export default ProjectsContextProvider;