import React, { useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Cards from '../../components/Card';
import { ProjectsContext } from '../../contexts/ProjectsContext';

const Projects = () => {
    const [ title, setTitle ] = useState('');
    const [ text, setText ] = useState('');
    const [ imgUrl, setImgUrl ] = useState('');
    const [ github, setGithub ] = useState('');
    const [ demo, setDemo ] = useState('');
    const [ editMode, setEditMode ] = useState(false);
    const [ showForm, setShowForm ] = useState(false);
    const [ uid, setUid ] = useState('');
    const { projects, projectDispatcher } = useContext(ProjectsContext);

    const handleSubmit = e => {
        e.preventDefault();
        !editMode 
            ? projectDispatcher({ type: 'ADD_PROJECT', project: {title, text, imgUrl, github, demo}})
            : projectDispatcher({ type: 'UPDATE_PROJECT', project: {id: uid, title, text, imgUrl, github, demo}});
        setEditMode(false);
        setShowForm(false);
    }

    const handleEdit = (id, title, text, imgUrl, github, demo) => {
        setEditMode(true);
        setShowForm(true);
        setUid(id);
        setTitle(title);
        setText(text);
        setImgUrl(imgUrl);
        setGithub(github);
        setDemo(demo);
    }

    const handleRemove = id => {
        projectDispatcher({ type: 'REMOVE_PROJECT', id});
    }

    const handleCancel = () => {
        setShowForm(false);
        cleanForm();
    }

    const cleanForm = () => {
        setUid('');
        setTitle('');
        setText('');
        setImgUrl('');
        setGithub('');
        setDemo('');
    }


    return ( 
        <Container className="d-flex flex-column w-100 mb-5">
            <Row className="justify-content-center">
                {!showForm ? <button className="btn btn--medium" onClick={() => setShowForm(true)}>Add New Project</button>
                :
                    <form className="d-flex flex-column" onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor="title">Title</label>
                        <input type="text" name="title" id="title" value={title} onChange={e => setTitle(e.target.value)} required/>

                        <label className="mt-3" htmlFor="text">Text</label>
                        <textarea name="text" id="text" cols="40" rows="7" value={text} onChange={e => setText(e.target.value)} required/>

                        <label htmlFor="imgurl" className="mt-2">img URL</label>
                        <input type="text" name="imgurl" id="imgurl" value={imgUrl} onChange={e => setImgUrl(e.target.value)} required/>

                        <label htmlFor="github" className="mt-2">Github</label>
                        <input type="text" name="github" id="github" value={github} onChange={e => setGithub(e.target.value)} />

                        <label htmlFor="demo" className="mt-2">Demo</label>
                        <input type="text" name="demo" id="demo" value={demo} onChange={e => setDemo(e.target.value)} />

                        <div className="d-flex mt-3 w-100">
                            <input className="btn mr-3 ml-auto" type="submit" value={editMode ? "Save changes" : "Add new Project"}/>
                            <button className="btn mr-auto" onClick={() => handleCancel() }>Cancel</button>
                        </div>
                    </form>
                }
            </Row>

            <Row className="mt-5 mb-5 projects_page">
                {
                    projects.length > 0 && projects.map( project => (
                        <Col xs="12" md="4" key={project.id}>
                            <Cards
                                type="projects_page--card" 
                                title={project.title}
                                text={project.text}
                                imgUrl={project.imgUrl}
                                github={project.github}
                                demo={project.demo}
                            >
                                <div className="mt-5 d-flex">
                                    <button className="footer-item" onClick={ () => handleEdit(project.id,project.title,project.text,project.imgUrl,project.github,project.demo)}>Edit Project</button>
                                    <button className="footer-item" onClick={ () => handleRemove(project.id)}>Remove Project</button>
                                </div>
                            </Cards>
                        </Col>

                    ))
                }
            </Row>
        </Container>
     );
}
 
export default Projects;