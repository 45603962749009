import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { 
    Container,
    Row,
    Col
} from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';

const Header = () => {
    const { auth } = useContext(AuthContext);
    const { isAuth } = auth;

    return (
        <header className="global_header">
            <Container>
                <Row>
                    <Col xs="12" lg="12" className="d-flex justify-content-center global_header--logo">
                        <Link to='/' >
                            <img src="/assets/images/logo_transparent.png" alt=""/>
                        </Link>
                    </Col>
                    <Col xs="12" lg="12" className="d-flex justify-content-center">
                        <nav className="global_header--nav">
                            <ul>
                                <li>
                                    <Link to='/' >Home</Link>
                                </li>
                                <li><Link to='/skills' >Skills</Link></li>
                                <li><Link to='/projects' >Projects</Link></li>
                                <li><a href="/assets/resume/resume.pdf" target="_blank" rel="noopener noreferrer">Resume</a></li>
                                { isAuth ? <li><Link to='/admin' >Admin</Link></li> : ''}
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default Header;