import React, { useContext } from 'react';
import { HomeContext } from '../../contexts/HomeContext'
import { Row, Col, Container } from 'react-bootstrap';
import Brands from '../../components/Brands';

const HomePage = () => {

    const { homeText } = useContext(HomeContext)


    return(
        <Container>
            <Row className="home_page">
                <Col xs="12" md="6" className="home_page--img">
                    <img src="/assets/icons/dev.svg" alt=""/>
                </Col>
                <Col xs="12" md="6" className="pt-5">
                    <h1 className="home_page--title">About Me</h1>
                    <p>
                        {homeText}
                    </p>
                    <Brands iconSize={3}/>
                </Col>
            </Row>
        </Container>
    )
}

export default HomePage;