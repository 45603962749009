import React from 'react';

const AdminNavbar = ({ menuItems, selected, setSelected }) => {

    const getItems = () => {
        const items = menuItems.map((item, i) => <li key={i} className={`${selected === item.item ? "active" : ""} btn`} onClick={() => setSelected(item.item)}>{item.item}</li>);
        return items;
    }


    return ( 
        <nav className="admin_navbar">
            <ul>
                { getItems() }
            </ul>
        </nav>
     );
}
 
export default AdminNavbar;