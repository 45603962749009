import React, { createContext, useReducer, useEffect } from 'react';
import { SkillsReducer } from '../reducers/SkillsReducer';
import firebase from 'firebase/app';
import 'firebase/firestore';

export const SkillsContext = createContext();

const SkillsContextProvider = (props) => {

    const [ skill, skillDispatch ] = useReducer(SkillsReducer, []);

      useEffect(() => {
        const db = firebase.firestore();

        db.collection("skills").orderBy("title", "asc").onSnapshot((querySnapshot => {
            const data = []            
            querySnapshot.forEach(doc => data.push({
                id: doc.id,
                title: doc.data().title,
                knowledge: doc.data().knowledge
            }));
            skillDispatch({ type: 'LOAD_DATA', db: data})
        }))
      }, []);

    return ( 
        <SkillsContext.Provider value={{skill, skillDispatch}}>
            {props.children}
        </SkillsContext.Provider>
     );
}
 
export default SkillsContextProvider;