import React, { useContext } from 'react';
import { SkillsContext } from '../../contexts/SkillsContext';
import { 
    ProgressBar,
    Row,
    Col,
    Container
} from 'react-bootstrap';

const SkillsPage = () => {

    const { skill } = useContext(SkillsContext);

    return(
        <Container>
            <Row className="skills_page">
                <Col xs="12" className="text-center mb-5 skills_page--title">
                    <h1>Skills</h1>
                </Col>
                {
                    skill.length > 0 && skill.map((skill, i) => (
                        <Col xs='12' md='3' className="mb-5" key={i}>
                            <p>{skill.title}</p>
                            <ProgressBar animated now={skill.knowledge} />
                        </Col>
                    ))
                }
            </Row>
        </Container>
    )
}

export default SkillsPage;